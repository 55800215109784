import useSwr from 'swr';
import client from 'src/utils/axios';
import config from 'src/config';
import { IParentCategory } from 'src/interface';

const fetcher = async (url: string) => {
  const response = await client.get(url);
  return response.data.categories;
};

const useGetAllCategories = () => {
  const apiUrl = `${config.apiUrl}/all-categories`;
  const { data: Categories } = useSwr<IParentCategory[]>(apiUrl, fetcher, {
    dedupingInterval: 10000,
  });
  if (Categories) {
    return { isLoading: false, Categories };
  }
  return { isLoading: true, Categories: [] };
};

export default useGetAllCategories;
